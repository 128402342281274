import React, { useState, useEffect } from 'react';
import axios from 'axios';

const GameList = () => {
    const [games, setGames] = useState([]);

    useEffect(() => {
        axios.get('http://localhost:5000/api/games')
            .then(response => setGames(response.data))
            .catch(error => console.error('Error fetching games:', error));
    }, []);

    return (
        <div>
            <h1>Games</h1>
            <ul>
                {games.map(game => (
                    <li key={game._id}>
                        <p>{game.name}</p>
                        <p>Leagues: {game.leagues.join(', ')}</p>
                        <p>Divisions: {game.divisions.join(', ')}</p>
                        <p>Servers: {game.servers.join(', ')}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default GameList;
