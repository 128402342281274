import React, { useState } from 'react';
import axios from 'axios';

const AddGame = () => {
    const [name, setName] = useState('');
    const [leagues, setLeagues] = useState('');
    const [divisions, setDivisions] = useState('');
    const [servers, setServers] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5000/api/games', {
                name,
                leagues: leagues.split(','),
                divisions: divisions.split(','),
                servers: servers.split(',')
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            console.log('Game added:', response.data);
        } catch (error) {
            console.error('Error adding game:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="Game Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <input
                type="text"
                placeholder="Leagues (comma separated)"
                value={leagues}
                onChange={(e) => setLeagues(e.target.value)}
            />
            <input
                type="text"
                placeholder="Divisions (comma separated)"
                value={divisions}
                onChange={(e) => setDivisions(e.target.value)}
            />
            <input
                type="text"
                placeholder="Servers (comma separated)"
                value={servers}
                onChange={(e) => setServers(e.target.value)}
            />
            <button type="submit">Add Game</button>
        </form>
    );
};

export default AddGame;
