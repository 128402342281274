import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className="about-container">
            <h1>About Us</h1>
            <p>Welcome to NGBoost, where we elevate your gaming experience to new heights. Our team of professional gamers and coaches are dedicated to helping you achieve your in-game goals, whether it's climbing the ranks in competitive play, mastering new strategies, or trading premium accounts.</p>

            <div className="mission">
                <h2>Our Mission</h2>
                <p>At NGBoost, our mission is to empower gamers around the world by providing top-tier boosting, coaching, and account trading services. We strive to enhance your gaming experience through our expertise and passion for gaming.</p>
            </div>

            <div className="vision">
                <h2>Our Vision</h2>
                <p>We envision a gaming community where every player can reach their full potential. Our vision is to be the go-to platform for gamers seeking to improve their skills, achieve higher ranks, and enjoy the best gaming experiences possible.</p>
            </div>

            <div className="values">
                <h2>Our Values</h2>
                <ul>
                    <li><strong>Integrity:</strong> We uphold the highest standards of integrity in all our actions.</li>
                    <li><strong>Excellence:</strong> We strive for excellence in everything we do.</li>
                    <li><strong>Innovation:</strong> We foster a culture of innovation to constantly improve our services.</li>
                    <li><strong>Passion:</strong> We are passionate about gaming and committed to our community.</li>
                    <li><strong>Customer Focus:</strong> We value our customers and aim to exceed their expectations.</li>
                </ul>
            </div>
        </div>
    );
};

export default AboutUs;
