import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';

const BoostForm = () => {
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const [league, setLeague] = useState('');
    const [division, setDivision] = useState('');
    const [lp, setLp] = useState(0);
    const [server, setServer] = useState('');
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        axios.get('http://localhost:5000/api/games')
            .then(response => setGames(response.data))
            .catch(error => console.error('Error fetching games:', error));
    }, []);

    const handleSubmit = () => {
        const requestData = {
            game: selectedGame,
            league,
            division,
            lp,
            server
        };

        axios.post('http://localhost:5000/api/requests', requestData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            console.log('Boost request submitted:', response.data);
        })
        .catch(error => {
            console.error('Error submitting boost request:', error);
        });
    };

    const handleStripeToken = async (token) => {
        const response = await axios.post('http://localhost:5000/api/payment', {
            token,
            amount
        });

        if (response.data.success) {
            console.log('Payment successful', response.data.charge);
            handleSubmit();
        } else {
            console.error('Payment failed', response.data.error);
        }
    };

    return (
        <div>
            <form>
                <select value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)}>
                    <option value="">Select a game</option>
                    {games.map(game => (
                        <option key={game._id} value={game.name}>{game.name}</option>
                    ))}
                </select>
                <input
                    type="text"
                    placeholder="League"
                    value={league}
                    onChange={(e) => setLeague(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Division"
                    value={division}
                    onChange={(e) => setDivision(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="LP"
                    value={lp}
                    onChange={(e) => setLp(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Server"
                    value={server}
                    onChange={(e) => setServer(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
                <StripeCheckout
                    stripeKey="pk_test_51PRVrQLMdy9QvlOrc3500Ra7MJLsqAUBnfWea0Qz1fKV9oleTmtn8dOCiSiJDkb7B2pFSZNFQbe0COJ0Vr4bOmSR002FOWyqm3"
                    token={handleStripeToken}
                    amount={amount * 100} // Amount in cents
                    name="Boost Service"
                />
            </form>
        </div>
    );
};

export default BoostForm;
