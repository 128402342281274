import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import registerBg from '../assets/img/dogs/register-bg.jpeg'; // Görseli import et

const Register = () => {
    const [username, setUser] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        if (password !== passwordRepeat) {
            alert("Passwords do not match");
            return;
        }
        try {
            const response = await axios.post('http://localhost:5000/api/auth/register', {
                username,
                firstName,
                lastName,
                email,
                password
            });
            alert('User registered successfully');
            navigate('/login'); // Başarılı kayıt sonrası yönlendirme
        } catch (error) {
            alert('Error registering user: ' + error.response.data);
        }
    };

    return (
        <div className="container">
            <div className="card shadow-lg o-hidden border-0 my-5">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-5 d-none d-lg-flex">
                            <div className="flex-grow-1 bg-register-image" style={{ backgroundImage: `url(${registerBg})` }}></div>
                        </div>
                        <div className="col-lg-7">
                            <div className="p-5">
                                <div className="text-center">
                                    <h4 className="text-dark mb-4">Create an Account!</h4>
                                </div>
                                <form className="user" onSubmit={handleRegister}>
                                    <div className="row mb-3">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <input
                                                className="form-control form-control-user"
                                                type="text"
                                                id="exampleFirstName"
                                                placeholder="First Name"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                className="form-control form-control-user"
                                                type="text"
                                                id="exampleLastName"
                                                placeholder="Last Name"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            className="form-control form-control-user"
                                            type="text"
                                            id="exampleUserName"
                                            placeholder="User Name"
                                            value={username}
                                            onChange={(e) => setUser(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            className="form-control form-control-user"
                                            type="email"
                                            id="exampleInputEmail"
                                            aria-describedby="emailHelp"
                                            placeholder="Email Address"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <input
                                                className="form-control form-control-user"
                                                type="password"
                                                id="examplePasswordInput"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                className="form-control form-control-user"
                                                type="password"
                                                id="exampleRepeatPasswordInput"
                                                placeholder="Repeat Password"
                                                value={passwordRepeat}
                                                onChange={(e) => setPasswordRepeat(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <button className="btn btn-primary d-block btn-user w-100" type="submit">Register Account</button>
                                    <hr />
                                    <a className="btn btn-primary d-block btn-google btn-user w-100 mb-2" role="button">
                                        <i className="fab fa-google"></i>&nbsp; Register with Google
                                    </a>
                                    <a className="btn btn-primary d-block btn-facebook btn-user w-100" role="button">
                                        <i className="fab fa-facebook-f"></i>&nbsp; Register with Facebook
                                    </a>
                                    <hr />
                                </form>
                                <div className="text-center">
                                    <a className="small" href="/forgot-password">Forgot Password?</a>
                                </div>
                                <div className="text-center">
                                    <a className="small" href="/login">Already have an account? Login!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
