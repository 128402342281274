import React, { useState, useEffect } from 'react';
import './Coaching.css';

const Coaching = () => {
    const [game, setGame] = useState('');
    const [coachingType, setCoachingType] = useState('regular');
    const [server, setServer] = useState('');
    const [hours, setHours] = useState(1);
    const [price, setPrice] = useState(0);

    useEffect(() => {
        const hourlyRate = coachingType === 'elite' ? 20 : 14;
        setPrice(hourlyRate * hours);
    }, [coachingType, hours]);

    return (
        <div className="coaching-container">
            <header className="coaching-header">
                <h1>Coaching Services</h1>
                <p>Improve your skills with our professional coaching</p>
            </header>
            <section className="coaching-form">
                <div className="form-group">
                    <label>Game</label>
                    <select value={game} onChange={(e) => setGame(e.target.value)}>
                        <option value="">Select Game</option>
                        <option value="League of Legends">League of Legends</option>
                        <option value="Valorant">Valorant</option>
                        <option value="TFT">TFT</option>
                        <option value="CS2">CS2</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Coaching Type</label>
                    <select value={coachingType} onChange={(e) => setCoachingType(e.target.value)}>
                        <option value="regular">Regular Coaching ($14/hour)</option>
                        <option value="elite">Elite Coaching ($20/hour)</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Server</label>
                    <select value={server} onChange={(e) => setServer(e.target.value)}>
                        <option value="">Select Server</option>
                        <option value="NA">NA</option>
                        <option value="EUW">EUW</option>
                        <option value="EUNE">EUNE</option>
                        <option value="KR">KR</option>
                        <option value="LAN">LAN</option>
                        <option value="LAS">LAS</option>
                        <option value="OCE">OCE</option>
                        <option value="TR">TR</option>
                        <option value="RU">RU</option>
                        <option value="BR">BR</option>
                        <option value="JP">JP</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Hours</label>
                    <input
                        type="number"
                        min="1"
                        value={hours}
                        onChange={(e) => setHours(parseInt(e.target.value))}
                    />
                </div>
                <div className="price-display">
                    <h3>Estimated Price: ${price.toFixed(2)}</h3>
                </div>
                <button className="btn btn-primary" type="submit">
                    Submit Coaching Request
                </button>
            </section>
        </div>
    );
};

export default Coaching;
