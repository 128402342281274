import React from 'react';
import { useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_test_51PRVrQLMdy9QvlOrc3500Ra7MJLsqAUBnfWea0Qz1fKV9oleTmtn8dOCiSiJDkb7B2pFSZNFQbe0COJ0Vr4bOmSR002FOWyqm3'); // Stripe publishable key

function Payment() {
    const location = useLocation();
    const { clientSecret, price } = location.state;

    const options = {
        clientSecret: clientSecret,
    };

    return (
        <div className="payment-container">
            <h1>Complete Your Payment</h1>
            <h3>Amount to be paid: ${price}</h3>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
            </Elements>
        </div>
    );
}

export default Payment;
