import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminDashboard.css';

const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [boostRequests, setBoostRequests] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersResponse = await axios.get('/api/admin/users', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setUsers(usersResponse.data);

                const boostRequestsResponse = await axios.get('/api/admin/boost-requests', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setBoostRequests(boostRequestsResponse.data);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="admin-dashboard">
            <h1>Admin Dashboard</h1>
            <div className="admin-sections">
                <div className="admin-section">
                    <h2>Manage Users</h2>
                    <ul>
                        {users.map((user) => (
                            <li key={user._id}>{user.username} - {user.email}</li>
                        ))}
                    </ul>
                </div>
                <div className="admin-section">
                    <h2>Manage Boost Requests</h2>
                    <ul>
                        {boostRequests.map((request) => (
                            <li key={request._id}>
                                {request.user.username} - {request.currentLeague} {request.currentDivision} to {request.desiredLeague} {request.desiredDivision} - ${request.price}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
