import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Boosting.css';

function Boosting() {
    const [game, setGame] = useState('');
    const [boostType, setBoostType] = useState('');
    const [currentLeague, setCurrentLeague] = useState('');
    const [currentDivision, setCurrentDivision] = useState('');
    const [currentLP, setCurrentLP] = useState('');
    const [desiredLeague, setDesiredLeague] = useState('');
    const [desiredDivision, setDesiredDivision] = useState('');
    const [desiredLP, setDesiredLP] = useState('');
    const [server, setServer] = useState('');
    const [queueType, setQueueType] = useState('');
    const [price, setPrice] = useState(null); // Başlangıçta null olacak
    const navigate = useNavigate();

    const calculatePrice = () => {
        const leaguePrices = {
            Iron: 10,
            Bronze: 20,
            Silver: 30,
            Gold: 40,
            Platinum: 50,
            Diamond: 60
        };

        const divisionPrices = {
            I: 4,
            II: 3,
            III: 2,
            IV: 1
        };

        const lpPrices = {
            '0-20': 1,
            '21-40': 2,
            '41-60': 3,
            '61-80': 4,
            '81-100': 5
        };

        const desiredLpPrices = {
            '0-10': 1,
            '11-20': 2,
            '21-30': 3,
            '31-40': 4,
            '41-50': 5
        };

        const basePrice = 
            leaguePrices[desiredLeague] + 
            divisionPrices[desiredDivision] + 
            desiredLpPrices[desiredLP];

        const finalPrice = basePrice * (queueType === 'Solo/Duo' ? 1.5 : 1); // Solo/Duo için çarpan

        setPrice(finalPrice);
    };

    useEffect(() => {
        if (
            game &&
            boostType &&
            currentLeague &&
            currentDivision &&
            currentLP &&
            desiredLeague &&
            desiredDivision &&
            desiredLP &&
            server &&
            queueType
        ) {
            calculatePrice();
        }
    }, [game, boostType, currentLeague, currentDivision, currentLP, desiredLeague, desiredDivision, desiredLP, server, queueType]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const paymentResponse = await axios.post('/api/payment/create-payment-intent', { price });
            const clientSecret = paymentResponse.data.clientSecret;
    
            // Stripe ödeme işlemi burada olacak
        } catch (error) {
            console.error('Payment error:', error);
        }
    };

    return (
        <div className="boosting-container">
            <h1>Boosting Services</h1>
            <form onSubmit={handleSubmit}>
                <div className="boosting-selection">
                    <div className="boosting-group">
                        <h2>Game</h2>
                        <div className="form-group">
                            <label>Game</label>
                            <select value={game} onChange={(e) => setGame(e.target.value)}>
                                <option value="">Select Game</option>
                                <option value="League of Legends">League of Legends</option>
                                <option value="Valorant">Valorant</option>
                                <option value="TFT">TFT</option>
                                <option value="CS2">CS2</option>
                            </select>
                        </div>
                    </div>
                    {game && (
                        <div className="boosting-group">
                            <h2>Boost Type</h2>
                            <div className="form-group">
                                <label>Boost Type</label>
                                <select value={boostType} onChange={(e) => setBoostType(e.target.value)}>
                                    <option value="">Select Boost Type</option>
                                    <option value="League Boosting">League Boosting</option>
                                    <option value="Duo Boosting">Duo Boosting</option>
                                    <option value="Win Boosting">Win Boosting</option>
                                    <option value="High Tier Boosting">High Tier Boosting</option>
                                    <option value="Placement Match">Placement Match</option>
                                    <option value="Pay per Game">Pay per Game</option>
                                    <option value="Champion Mastery">Champion Mastery</option>
                                    <option value="Clash Boosting">Clash Boosting</option>
                                    <option value="Level Up Boosting">Level Up Boosting</option>
                                    <option value="Arena Boosting">Arena Boosting</option>
                                    <option value="Challenge Boosting">Challenge Boosting</option>
                                </select>
                            </div>
                        </div>
                    )}
                    {boostType && (
                        <>
                            <div className="boosting-group">
                                <h2>Current League</h2>
                                <div className="form-group">
                                    <label>League</label>
                                    <select value={currentLeague} onChange={(e) => setCurrentLeague(e.target.value)}>
                                        <option value="">Select League</option>
                                        <option value="Iron">Iron</option>
                                        <option value="Bronze">Bronze</option>
                                        <option value="Silver">Silver</option>
                                        <option value="Gold">Gold</option>
                                        <option value="Platinum">Platinum</option>
                                        <option value="Emerald">Emerald</option>
                                        <option value="Diamond">Diamond</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Division</label>
                                    <select value={currentDivision} onChange={(e) => setCurrentDivision(e.target.value)}>
                                        <option value="">Select Division</option>
                                        <option value="I">Division I</option>
                                        <option value="II">Division II</option>
                                        <option value="III">Division III</option>
                                        <option value="IV">Division IV</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Current LP</label>
                                    <select value={currentLP} onChange={(e) => setCurrentLP(e.target.value)}>
                                        <option value="">Select LP</option>
                                        <option value="0-20">0-20</option>
                                        <option value="21-40">21-40</option>
                                        <option value="41-60">41-60</option>
                                        <option value="61-80">61-80</option>
                                        <option value="81-100">81-100</option>
                                    </select>
                                </div>
                            </div>
                            <div className="boosting-group">
                                <h2>Desired League</h2>
                                <div className="form-group">
                                    <label>League</label>
                                    <select value={desiredLeague} onChange={(e) => setDesiredLeague(e.target.value)}>
                                        <option value="">Select League</option>
                                        <option value="Iron">Iron</option>
                                        <option value="Bronze">Bronze</option>
                                        <option value="Silver">Silver</option>
                                        <option value="Gold">Gold</option>
                                        <option value="Platinum">Platinum</option>
                                        <option value="Emerald">Emerald</option>
                                        <option value="Diamond">Diamond</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Division</label>
                                    <select value={desiredDivision} onChange={(e) => setDesiredDivision(e.target.value)}>
                                        <option value="">Select Division</option>
                                        <option value="I">Division I</option>
                                        <option value="II">Division II</option>
                                        <option value="III">Division III</option>
                                        <option value="IV">Division IV</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>LP Gain</label>
                                    <select value={desiredLP} onChange={(e) => setDesiredLP(e.target.value)}>
                                        <option value="">Select LP</option>
                                        <option value="0-10">10-19</option>
                                        <option value="11-20">20-24</option>
                                        <option value="21-30">25-29</option>
                                        <option value="31-40">30+</option>
                                    </select>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {boostType && (
                    <div className="boosting-group full-width">
                        <h2>Server and Queue Type</h2>
                        <div className="form-group">
                            <label>Server</label>
                            <select value={server} onChange={(e) => setServer(e.target.value)}>
                                <option value="">Select Server</option>
                                <option value="EUW">Europe West</option>
                                <option value="EUNE">Europe Nordic & East</option>
                                <option value="NA">North America</option>
                                <option value="OCE">Oceania</option>
                                <option value="RU">Russia</option>
                                <option value="TR">Türkiye</option>
                                <option value="LAN">Latin America North</option>
                                <option value="LAS">Latin America South</option>
                                <option value="BR">Brazil</option>
                                <option value="JP">Japan</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Queue Type</label>
                            <select value={queueType} onChange={(e) => setQueueType(e.target.value)}>
                                <option value="">Select Queue Type</option>
                                <option value="Solo/Duo">Solo/Duo</option>
                                <option value="Flex">Flex</option>
                            </select>
                        </div>
                    </div>
                )}
                <div className="price-display">
                    <h3>Estimated Price: ${price !== null ? price.toFixed(2) : 'Calculating'}</h3>
                </div>
                <div className="submit-button-container">
                    <button className="btn btn-primary" type="submit">
                        Submit Boost Request
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Boosting;
