import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPanel = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await axios.get('http://localhost:5000/api/auth/users', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUsers(response.data);
        };

        fetchUsers();
    }, []);

    const handleRoleChange = async (userId, newRole) => {
        try {
            await axios.put(`http://localhost:5000/api/auth/users/${userId}/role`, { role: newRole }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUsers(users.map(user => user._id === userId ? { ...user, role: newRole } : user));
        } catch (error) {
            console.error('Error updating user role:', error);
        }
    };

    return (
        <div>
            <h1>Admin Panel</h1>
            <ul>
                {users.map(user => (
                    <li key={user._id}>
                        {user.username} - {user.role}
                        <select value={user.role} onChange={(e) => handleRoleChange(user._id, e.target.value)}>
                            <option value="User">User</option>
                            <option value="Booster">Booster</option>
                            <option value="Admin">Admin</option>
                        </select>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminPanel;
