import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BoostRequestList = () => {
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        axios.get('http://localhost:5000/api/requests', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => setRequests(response.data))
        .catch(error => console.error('Error fetching boost requests:', error));
    }, []);

    const handleStatusChange = (id, status) => {
        axios.put(`http://localhost:5000/api/requests/${id}`, { status }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            setRequests(requests.map(request => request._id === id ? { ...request, status } : request));
        })
        .catch(error => console.error('Error updating boost request:', error));
    };

    return (
        <div>
            <h1>Boost Requests</h1>
            <ul>
                {requests.map(request => (
                    <li key={request._id}>
                        <p>{request.game} - {request.league} - {request.division} - {request.lp} LP - {request.server}</p>
                        <p>Status: {request.status}</p>
                        <button onClick={() => handleStatusChange(request._id, 'In Progress')}>In Progress</button>
                        <button onClick={() => handleStatusChange(request._id, 'Completed')}>Completed</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BoostRequestList;
