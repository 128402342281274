import React from 'react';
import './HeroSection.css';

function HeroSection() {
    return (
        <div className="hero-container">
            <h1>BOOST YOUR GAME</h1>
            <p>What are you waiting for?</p>
        </div>
    );
}

export default HeroSection;
