import React from 'react';
import './ServicesSection.css';
import { Link } from 'react-router-dom';

function ServicesSection() {
    return (
        <div className="services-container">
            <h1>Our Services</h1>
            <div className="services-wrapper">
                <div className="service">
                    <Link to="/boosting" className="service">
                        <h2>Boosting</h2>
                        <p>Ascend the Ranks. Dominate the League</p>
                    </Link>
                </div>
                <div className="service">
                    <Link to="/coaching" className="service">
                        <h2>Coaching</h2>
                        <p>Strategize to Victory. Personalized Coaching.</p>
                    </Link>
                </div>
                <div className="service">
                    <Link to="/account-trading" className="service">
                        <h2>Account Trading</h2>
                        <p>Premium Accounts. Unlock Your Potential.</p>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ServicesSection;
