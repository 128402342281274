import React from 'react';
import './AccountTrading.css';

const AccountTrading = () => {
    return (
        <div className="account-trading-container">
            <h1>Account Trading Service Coming Soon!</h1>
        </div>
    );
}

export default AccountTrading;
