import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode }from 'jwt-decode';
import './Navbar.css';

const Navbar = () => {
    const [user, setUser] = useState(null);
    const [menuActive, setMenuActive] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            setUser(decodedToken.user);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setUser(null);
        window.location.href = '/login';
    };

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="navbar-logo">
                    <Link to="/">NGBoost</Link>
                </div>
                <div className="menu-icon" onClick={toggleMenu}>
                    <i className={menuActive ? 'fa fa-times' : 'fa fa-bars'}></i>
                </div>
                <ul className={menuActive ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <Link to="/boosting" className="nav-links" onClick={toggleMenu}>
                            Boosting
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/coaching" className="nav-links" onClick={toggleMenu}>
                            Coaching
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/account-trading" className="nav-links" onClick={toggleMenu}>
                            Account Trading
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about" className="nav-links" onClick={toggleMenu}>
                            About Us
                        </Link>
                    </li>
                    {user && user.role === 'Admin' && (
                        <li className="nav-item">
                            <Link to="/admin-dashboard" className="nav-links">
                                Admin Panel
                            </Link>
                        </li>
                    )}
                </ul>
                <div className="nav-login">
                    {user ? (
                        <div className="dropdown">
                            <button className="dropbtn">
                                {user.username} <i className="fa fa-caret-down"></i>
                            </button>
                            <div className="dropdown-content">
                                <Link to="/profile">Profile</Link>
                                <Link to="/orders">Orders</Link>
                                <a href="#!" onClick={handleLogout}>Logout</a>
                            </div>
                        </div>
                    ) : (
                        <Link to="/login" className="nav-links login-button">
                            Login
                        </Link>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
