import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPassword.css'; // CSS dosyasını import et

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5000/api/auth/forgot-password', { email });
            setMessage('Recovery email sent. Please check your inbox.');
        } catch (error) {
            setMessage('Error sending recovery email. Please try again.');
        }
    };

    return (
        <div className="forgot-password-container">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Forgot Password</h4>
                            <form onSubmit={handleForgotPassword}>
                                <div className="form-group">
                                    <label htmlFor="email">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <br />
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                            {message && <p>{message}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
