import React, { useEffect, useState } from 'react'; // useState'i buraya ekleyin
import {jwtDecode} from 'jwt-decode'; // jwtDecode'i buraya ekleyin
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import BoostForm from './components/BoostForm';
import BoostRequestList from './components/BoostRequestList';
import Login from './components/Login';
import Register from './components/Register';
import AddGame from './components/AddGame';
import GameList from './components/GameList';
import Profile from './components/Profile';
import AdminPanel from './components/AdminPanel';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import './App.css'; // Stil dosyasını import ediyoruz
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
import Footer from './components/Footer';
import AdminDashboard from './components/AdminDashboard';
import Boosting from './components/Boosting';
import Coaching from './components/Coaching';
import AccountTrading from './components/AccountTrading';
import Payment from './components/Payment';
import AboutUs from './components/AboutUs';

function App() {
    const [userRole, setUserRole] = useState(''); // useState'i burada kullanıyoruz

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken.user && decodedToken.user.role) {
                    setUserRole(decodedToken.user.role);
                } else {
                    console.error('Token does not contain user role information');
                }
            } catch (error) {
                console.error('Invalid token', error);
            }
        }
    }, []);

    return (
        <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<><HeroSection /></>} />
                    <Route path="/boost" element={<BoostForm />} />
                    <Route path="/requests" element={<BoostRequestList />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/add-game" element={<AddGame />} />
                    <Route path="/games" element={<GameList />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/admin" element={<AdminPanel />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/services" element={<ServicesSection />} />
                    <Route path="/boosting" element={<Boosting />} />
                    <Route path="/coaching" element={<Coaching />} />
                    <Route path="/account-trading" element={<AccountTrading />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route path="/about" element={<AboutUs />} />
                    {userRole === 'Admin' && <Route path="/admin-dashboard" element={<AdminDashboard />} />}
                </Routes>
                <Footer /> 
        </Router>
    );
}

export default App;
